import { Route } from "react-router-dom";

import fep from "../App/Router/loadable";

import fepAppMenus from "../App/config/menus";

const AppId = "FEP";
localStorage.setItem("AppId", AppId);

export default [
  {
    component: Route,
    id: AppId,
    props: {
      title: "FEP",
      path: `/fep`,
      component: fep,
      icon: "border"
    },
    menus: fepAppMenus
  }
];
