import { userFunctions } from "./user-functions";
import { emitter } from "cornerstone_ui_core/lib/containers/BaseLayout/Menu/MenuMap/PopupSubMenu";

export default function(basePath) {
  return [
    {
      title: "Search",
      path: `${basePath}`,
      icon: "search"
    }
  ];
}
