import React, { Component } from "react";
import CornerstoneApp from "cornerstone_ui_core/lib/CornerstoneApp";
import { history } from "cornerstone_ui_core/lib/baseStore";
import AppRouter from "cornerstone_ui_core/lib/components/AppRouter";

import "../assets/scss/custom.scss";
import "../assets/scss/globalStyle.scss";

import apps from "../config/apps";
localStorage.removeItem("jwtToken");

class App extends Component {
  render() {
    return (
      <CornerstoneApp>
        <AppRouter defaultRoute={`/fep`} routes={apps} history={history} />
      </CornerstoneApp>
    );
  }
}

export default App;
